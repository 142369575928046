.manage-products-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f4f6f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.title {
  font-size: 24px;
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

.sign-in-btn, .sign-out-btn {
  display: inline-block;
  margin: 10px auto;
  padding: 10px 20px;
  background-color: #1a73e8;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  text-align: center;
}

.sign-in-btn:hover, .sign-out-btn:hover {
  background-color: #1558c0;
}

.products-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.products-table th, .products-table td {
  padding: 15px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.products-table th {
  background-color: #007bff;
  color: white;
  font-size: 16px;
}

.products-table td {
  font-size: 14px;
  color: #333;
}

.products-table tr:hover {
  background-color: #f1f1f1;
}

input[type="text"], input[type="checkbox"] {
  padding: 8px;
  width: 100%;
  border-radius: 5px;
  border: 1px solid #ccc;
}

button.add-desc-btn, button.add-photo-btn {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}

button.remove-desc-btn, button.remove-photo-btn {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px;
}

button.save-btn {
  background-color: #007bff;
  color: white;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button.edit-btn {
  background-color: #17a2b8;
  color: white;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button.delete-btn {
  background-color: #dc3545;
  color: white;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .products-table, .products-table th, .products-table td {
    font-size: 12px;
  }
}

input[type="text"]:focus {
  width: 450%;
  height: 40px;
  transition: all 0.3s ease;
  outline: none;
  box-shadow: 0 0 10px rgba(33, 150, 243, 0.7);
  border: 2px solid #2196F3;
  position: relative;
  z-index: 10;
}

input[type="text"] {
  transition: all 0.3s ease;
  padding: 8px;
  width: 100%;
  border-radius: 5px;
  border: 1px solid #ccc;
}

input[type="checkbox"] {
  transform: scale(1.2);
}

button.add-desc-btn, button.add-photo-btn {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}

button.add-desc-btn:hover, button.add-photo-btn:hover {
  background-color: #218838;
}

button.remove-desc-btn, button.remove-photo-btn {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

button.remove-desc-btn:hover, button.remove-photo-btn:hover {
  background-color: #c82333;
}

.products-table th, .products-table td {
  padding: 15px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.products-table th.description-column {
  width: 40%;  /* Agranda la columna de descripción */
}

.products-table th.photos-column {
  width: 15%;  /* Achica la columna de fotos */
}

input[type="text"]:focus {
  width: 240%;
  height: 40px;
  transition: all 0.3s ease;
  outline: none;
  box-shadow: 0 0 10px rgba(33, 150, 243, 0.7);
  border: 2px solid #2196F3;
  position: relative;
  z-index: 10;
}

input[type="text"], input[type="checkbox"] {
  padding: 8px;
  width: 100%;
  border-radius: 5px;
  border: 1px solid #ccc;
}

