.styleNav {
    background-color: #deb841; /* Color de fondo sólido */
    font-size: 16px;
    font-weight: 600;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* Sombra para dar profundidad */
    border-bottom: 3px solid #de9e36; /* Borde inferior para destacar */
}

.nav-link {
    color: #FFFFFF !important; /* Cambiar el color del texto a blanco para mayor contraste */
}

.navbar-brand img {
    filter: brightness(0) invert(1); /* Si el logo es oscuro, invertir colores */
}

.logoSitel {
    border-radius: 13%; /* Bordes redondeados para un aspecto circular */
    border: 1px solid #e0e0e0; /* Borde en rojo para destacar el logo */
    padding: 1px; /* Espaciado interno para que el borde no toque la imagen */
    background-color: #f3f3f3; /* Fondo blanco para hacer que el logo resalte */
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2); /* Sombra sutil para darle profundidad */
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Transiciones suaves para efectos de hover */
}

.logoSitel:hover {
    transform: scale(1.05); /* Efecto de agrandamiento al hacer hover */
    box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.3); /* Sombra aumentada en hover */
}


.activeC {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #bfbdc1 !important;
    border-bottom: 3px solid #bfbdc1; /* Borde más grueso para mayor impacto */
}

.text-nav {
    color: #FFFFFF !important; /* Cambiar a blanco para mayor contraste sobre fondo oscuro */
    text-decoration: none;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    transition: color 0.3s ease-in-out; /* Transición suave para el color */
}

.text-nav:hover {
    color: #6d6a75 !important; /* Cambiar el color al hacer hover */
}

.dropdown-menu{
    background-color: #deb841;
}

.efect-nav {
    background-image: linear-gradient(to right,
        #6d6a75,
        #6d6a75 50%,
        #FFFFFF 50%); /* Colores actualizados */
    background-size: 200% 100%;
    background-position: -100%;
    display: inline-block;
    padding: 5px 0;
    position: relative;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    transition: all 0.3s ease-in-out;
}

.efect-nav:before {
    content: '';
    background: #6d6a75;
    display: block;
    position: absolute;
    bottom: -3px;
    left: 0;
    width: 0;
    height: 3px;
    transition: all 0.3s ease-in-out;
}

.efect-nav:hover {
    background-position: 0;
    color: #6d6a75; /* Colores actualizados */
}

.efect-nav:hover:before {
    width: 100%;
}

.cart-icon {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-left: auto; /* Empuja el ícono hacia la derecha */
  }
  
  .cart-img {
    width: 40px;
    height: 40px;
    filter: brightness(0) invert(1); /* Hacerlo blanco */
  }
  
  
  .cart-icon span {
    position: absolute;
    bottom: -5px;
    right: -5px;
    background-color: #E62D5E; /* Rojo vibrante para destacar */
    color: #ffffff; /* Texto blanco paramayor contraste */
    padding: 1px 5px; /* Ajuste de padding para mejor proporción */
    border-radius: 50%; /* Bordes completamente redondeados */
    font-size: 10px; /* Tamaño de fuente ligeramente mayor */
    font-weight: bold;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2); /* Sombra suave para profundidad */
    border: 1px solid #ffffff; /* Borde blanco para mayor visibilidad */
  }
  
  
