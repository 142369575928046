.cont-productos{
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0% 10% 5% 10%;
}

.product-card{
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.125);
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.35);
    border-radius: 10px;
    max-width: 200px;
    transition: 1s;
    margin-bottom: 10px;
}

.product-card:hover{
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.35);
    border-radius: 10px;
    max-width: 200px;
    transform: scale(1.05);
    margin-bottom: 10px;
}

.img-card-product{
    width: 199px;
    height: 200px;
    border-radius: 10px 10px 0px 0px;
}

.list-product-container{
    margin: 0px 20px 0px 0px;
}

.line{
    border: 1px solid rgba(0, 0, 0, 0.125);
    margin: 0px 0px 0px 0px;
}

.fuegos-hornalla{
    width: 100%;
    max-width: 561px;
}

