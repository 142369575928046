.receta-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0px 0px 10px 0px;
}

.fix-position{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.img-receta{
    max-width: 700px;
    width: 100%;
}

.cont-img{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 10px 0px 10px 0px;
}

.cont-prod{
    width: 100%;
}

.name-recipe{
    color:#f9434c;
    font-size: 1.7rem;
    font-weight: 600;
    text-align: center;
}

.img-comen{
    margin: 0px 5px 0px 5px;
}

.contener-global-receta{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0px 0px 10px 0px;
    max-width: 1000px;
}

.text-style{
    font-size: 1.2rem;
    font-weight: 600;
    text-align: center;
    margin: 30px 0px 00px 0px;
}

.line{
    border: 1px solid rgba(0, 0, 0, 0.125);
    margin: 0px 0px 30px 0px;
}

.cont-prepa{
    width: 100%;
    padding: 0px 0px 10px 0px;
    max-width: 1000px;
}