.recipes-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
    width: 90%;
    padding: 0 20px;
}

.recipes-form{
    min-width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.order-input{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}

.text-input{
    width: 100%;
    border-radius: 10px;
}

.cant-piezas{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.format-pieza{
    background-color: rgb(113, 115, 229);
    color: white;
    text-align: center;
    width: 100%;
    border-radius: 10px;
    padding: 10px;
}

.box-select{
    margin: 5px;
}

.buttons-form{
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 15px 0;
}

.button-add{
    background-color: blueviolet;
    color: white;
    border-radius: 10px;
    padding: 4px;
    margin: 0 5px;
    font-weight: 700;
    max-width: 480px;
}

.button-remove{
    background-color: rgb(224, 90, 90);
    color: white;
    border-radius: 10px;
    padding: 4px;
    margin: 0 5px;
    font-weight: 700;
    max-width: 480px;
}

.button-build-recipe{
    background-color: rgb(113, 115, 229);
    color: white;
    border-radius: 10px;
    width: 200px;
    padding: 4px;
    font-weight: 700;
    text-align: center;
    cursor: pointer;
    margin-top: 30px;
    font-size: 1.3rem;
    margin-bottom: 30px;
    border: 1px solid rgb(40, 40, 40);
}