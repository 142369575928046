.animate-charcter {
    text-transform: uppercase;
    background-image: linear-gradient(-225deg,
            #bfbdc1 0%,
            #6d6a75 29%,
            #37323e 67%,
            #de9e36 100%);
    background-size: 200% auto;
    color: #fff;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: textclip 10s linear infinite;
    display: inline-block;
    font-size: 15px; /* Tamaño de fuente aumentado para mayor impacto */
    text-align: center;
    width: 100%;
    font-weight: 700; /* Peso de fuente aumentado para resaltar el texto */
    padding: 5px 0; /* Espaciado adicional para mejorar la legibilidad */
    letter-spacing: 2px; /* Espaciado entre letras para mayor claridad */
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* Sombra sutil para dar profundidad */
    margin-bottom: 15px;
}

@keyframes textclip {
    to {
        background-position: -200% center;
    }
}


.text-cool{
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: #212529;
}

.centerCarrosel {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f5f5f5; /* Fondo suave para dar contraste */
    padding: 20px; /* Espaciado adicional */
    border-radius: 10px; /* Bordes redondeados */
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1); /* Sombra para profundidad */
}

.carrouselMaxW {
    max-width: 1000px;
    width: 100%; /* Asegura que el contenido ocupe todo el ancho disponible */
    border-radius: 8px; /* Bordes redondeados */
    overflow: hidden; /* Asegura que el contenido no sobresalga */
}

.banner-dos{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 3% 0% 3% 0%;
    background-color: #f5f5f5; /* Fondo suave para dar contraste */
    padding: 20px; /* Espaciado adicional */
    border-radius: 10px; /* Bordes redondeados */
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1); /* Sombra para profundidad */
    width: 100%;
    max-width: 1000px;
}

.centerBanner-dos{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.size-icon-banner{
    width: 60px;
    height: 60px;
    margin: 10px 0px 10px 0px;
}

.position-item-banner{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.title-home{
    font-weight: 400;
    font-size: 22px;
    line-height: 27px;
    text-align: center;
    color: #212529;
    margin: 30px 0px 30px 0px;
}

.img-category{
    width: 200px;
    height: 200px;
    border-radius: 10px 10px 0px 0px;
}

.card-category{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 10px 0px 10px 0px;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.125);
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.35);
    border-radius: 10px;
    max-width: 200px;
    margin: 2%;
    text-decoration: none !important;
}

.container-category{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 0% 10% 0% 10%;
}

.text-category{
    font-weight: 600;
    font-size: 16px;
    line-height: 23px;
    color: #212529;
    margin: 0% 0% 5% 5%;
    padding-top: 8px;
    text-shadow: 1px 1px 1px rgba(0,0,0,0.3); 
}