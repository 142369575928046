.cart-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.cart-list {
  list-style: none;
  padding: 0;
  width: 100%;
}

.cart-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ccc;
  padding: 15px 0;
  max-width: 800px;
  margin: 0 auto;
}

.cart-item-img {
  width: 60px;
  height: 60px;
  object-fit: cover;
  margin-right: 20px;
  border-radius: 8px;
}

.cart-item-info {
  flex-grow: 1;
  text-align: left;
  margin-right: 20px;
}

.cart-item-controls {
  display: flex;
  align-items: center;
}

.cart-item-controls button {
  background-color: #1A237E;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  margin: 0 5px;
  border-radius: 5px;
}

.cart-item-controls span {
  padding: 0 10px;
  font-size: 16px;
}

.remove-btn {
  background-color: red;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 5px;
}

.clear-cart-btn {
  background-color: #1A237E;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  margin-top: 20px;
  text-align: center;
  width: 100%;
  max-width: 800px;
  border-radius: 5px;
}

.cart-item-name {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
  margin-right: 20px;
}

.send-whatsapp-btn {
  background-color: #25D366;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  margin-top: 20px;
  display: inline-block;
  transition: background-color 0.3s ease;
}

.send-whatsapp-btn:hover {
  background-color: #1EBE50;
}

.send-whatsapp-btn:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(37, 211, 102, 0.5);
}

.send-email-btn {
  background-color: #007BFF;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  margin-top: 20px;
  display: inline-block;
  transition: background-color 0.3s ease;
}

.send-email-btn:hover {
  background-color: #0056b3;
}

.send-email-btn:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5);
}

.go-to-products-button {
  background-color: #3498db; /* Color azul vibrante */
  color: #ffffff; /* Texto en blanco */
  padding: 10px 20px; /* Espaciado interno */
  border: none; /* Sin borde */
  border-radius: 5px; /* Bordes redondeados */
  cursor: pointer; /* Manito al pasar el mouse */
  font-size: 16px; /* Tamaño de fuente */
  transition: background-color 0.3s ease; /* Transición suave */
  margin-top: 20px; /* Margen superior */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Sombra suave */
}

.go-to-products-button:hover {
  background-color: #2980b9; /* Color al pasar el mouse */
}

.centerText{
  text-align: center;
}

