.cardProducts{
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.125);
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.35);
    border-radius: 10px;
    max-width: 200px;
    margin: 2% !important;
    transition: 1s;
}

.cardProducts:hover{
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.35);
    border-radius: 10px;
    max-width: 200px;
    margin: 2% !important;
    transform: scale(1.05);
}

.img-card-product{
    width: 199px;
    height: 200px;
    border-radius: 10px 10px 0px 0px;
}

.category-product{
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #F9434C;
    margin: 5% 0% 5% 5%;
    text-shadow: 1px 1px 1px rgba(0,0,0,0.3); 
}

.title-product{
    font-weight: 400;
    height: 46px;
    font-size: 16px;
    line-height: 23px;
    color: #212529;
    margin: 0% 0% 5% 5%;
    text-shadow: 1px 1px 1px rgba(0,0,0,0.3); 

}

         
.btn-prod {
    text-decoration: none;
    margin: 5%;
    padding: 5px 5px;
    text-align: center;
    color: rgb(0, 0, 0);
    text-shadow: 2px 2px 3px rgba(0,0,0,0.6);
    display: block;
    font-weight: 400;
    font-size: 14px;
    width: 90%;
  }

  .btn-prod-dec{
    text-decoration: none;
  }

  .btn-prod:hover {
    background-position: right center; /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
  }
 

           
  .btn-grad {
    background-image: linear-gradient(to right, #eacda3 0%, #d6ae7b  51%, #eacda3  100%);
    margin: 10px;
    padding: 15px 45px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;            
    box-shadow: 0 0 20px #eee;
    border-radius: 10px;
    display: block;
  }

  .btn-grad:hover {
    background-position: right center; /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
  }

  .container-featured-products{
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0% 10% 5% 10%;
  }
 
  .form-control{
    border-radius: 10px;
    border: 1px solid #ced4da;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    width: 80%;
    margin: 0% 10% 5% 10%;
  }

  .banner-products{
    width: 100%;
    margin: 1% 0% 1% 0%;
    border-radius: 5%;
}

.colores-disp{
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin: 0% 2% 0% 2%;
  background-color: rgb(255, 255, 255);
}

.title-colors{
  font-weight: 400;
  font-size: 13px;
  line-height: 23px;
  color: #212529;
  text-shadow: 1px 1px 1px rgba(0,0,0,0.3); 
}

.colors{
  display: flex;
  flex-direction: row;
}

.colors-container{
  display: flex;
  flex-direction: column;
  justify-content: end;
  flex-wrap: wrap;
  margin: 0% 5% 5% 5%;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.35);
  border-radius: 10px;
  padding: 0% 5% 5% 5%;
}

.breadcrumb-nav {
  display: flex;
  align-items: center;
  margin: 20px 0;
  font-size: 14px;
}

.breadcrumb-link {
  color: #007BFF;
  text-decoration: none;
  margin-right: 5px;
  transition: color 0.2s ease-in-out;
}

.breadcrumb-link:hover {
  color: #0056b3;
}

.breadcrumb-current {
  color: #6c757d;
  margin-left: 5px;
}

.breadcrumb-container{
  width: 80%;
  margin:0% 10% 0% 10%;
}