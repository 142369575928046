@import url('https://fonts.googleapis.com/css2?family=Montserrat+Alternates:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;1,100;1,300;1,400;1,500&display=swap');

* {
  /* Reset de margenes, bordes, etc.. */
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

* {
  /* Limpiamos márgenes y estilos provenientes de HTML */
  text-decoration: none;
  list-style-type: none;
}

*{
  font-family: 'Montserrat Alternates', sans-serif;
  font-weight: 400;
}

html{
  background-color: #F9F5F1;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.botonF1 {
  width: 50px;
  height: 50px;
  margin-right: 10px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.botonF1:hover {
  transform: scale(1.1); /* Efecto de agrandamiento al pasar el mouse */
  box-shadow: 0px 4px 10px rgba(0, 128, 0, 0.4); /* Sombra suave en hover */
}

.fixedContact {
  right: 0;
  bottom: 0px;
  position: fixed;
  margin-bottom: 16px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0px 16px 16px 0px;
  background-color: #25D366; /* Color verde de WhatsApp */
  padding: 10px 20px; /* Espaciado más generoso */
  border-radius: 50px; /* Bordes redondeados */
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2); /* Sombra suave */
  transition: background-color 0.3s ease;
}

.fixedContact:hover {
  background-color: #128C7E; /* Cambiar color al hacer hover */
}

.text-contact-fixed {
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  margin-left: 10px;
}
