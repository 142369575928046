.unite-a-mi-equipo-container{
    max-width: 1230px;
    margin: 0 auto;
}


.video-responsive {
    position: relative;
    padding-bottom: 56.25%; /* 16/9 ratio */
    padding-top: 30px; /* IE6 workaround*/
    height: 0;
    overflow: hidden;
    }
    
    .video-responsive iframe,
    .video-responsive object,
    .video-responsive embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    }

    .video-player{
        z-index: 1;
    }

    .text-unite{
        color: rgb(0, 0, 0);
        text-align: center;
        margin: 0% 0% 1% 0%;
    }

    .contenido-unite{
        z-index: 2;
        transform: translate(0%, 0%);
        color: rgb(0, 0, 0);
        text-align: center;
        background-color: white;
        width: 100%;
        height: 100%;
    }

    .title-beneficios{
        margin-top: 15px;
    }

    .item-beneficios{
        width: 240px;
        height: 200px;
    }

    .ventajas-unite{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        margin-top: 30px;
    }

    .acomodar-items{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 30px;
    }

    .text-card{
        text-align: center;
        margin-top: 20px;
        font-size: 1.1rem;
    }

    .text-desc{
        margin-top: 30px;
    }


    .button-contacto{
        padding: 15px;
        font-size: 1.2rem;
        margin-top: 30px;
        margin-bottom: 30px;
        font-weight: 700;
        border-radius: 5px;
        background-color: rgb(0, 0, 0);
        color: white;
        cursor: pointer;
    }

    .button-contacto:hover{
        background-color: rgb(255, 255, 255);
        color: rgb(0, 0, 0);
        border: 1px solid rgb(0, 0, 0);
    }
