.add-product-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f4f4f4;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .title {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
  }
  
  .product-form {
    display: flex;
    flex-direction: column;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border-radius: 4px;
    border: 1px solid #ccc;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  
  .description-group,
  .photo-group {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .add-btn,
  .remove-btn {
    margin-left: 10px;
    padding: 5px 10px;
    background-color: #1A237E;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .add-btn:hover,
  .remove-btn:hover {
    background-color: #0d1841;
  }
  
  .submit-btn {
    width: 100%;
    padding: 10px 0;
    background-color: #E62D5E;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    margin-top: 20px;
  }
  
  .submit-btn:hover {
    background-color: #c02549;
  }

  .sign-in-btn, .sign-out-btn {
    background-color: #4285F4; /* Azul de Google */
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    display: inline-block;
    margin: 10px 0;
}

.sign-in-btn:hover, .sign-out-btn:hover {
    background-color: #357AE8; /* Azul más oscuro al pasar el cursor */
}

.sign-in-btn:active, .sign-out-btn:active {
    background-color: #2A65D3; /* Azul más oscuro al hacer clic */
}

.sign-in-btn:focus, .sign-out-btn:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(66, 133, 244, 0.5); /* Sombra al recibir foco */
}

  