.banner-products {
  width: 100%;
  margin: 1% 0% 1% 0%;
}

.detailImg {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 600px;
}

.detailContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
  background: #fafbfc;
  border-radius: 28px;
  margin: 0% 5% 5% 5%;
  max-width: 1000px;
}

.title-detail-item {
  text-align: left;
  font-family: Arial rgb(139, 73, 73);
  font-weight: bold;
  font-size: 30px;
  background: #646464;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  color: transparent;
  text-shadow: 0px 3px 3px rgba(255, 255, 255, 0.4),
    0px -1px 1px rgba(0, 0, 0, 0.3);
}

.producto-destacado {
  background-color: red;
  color: white;
  text-align: center;
  font-size: 16px;
  border-radius: 10px;
  padding: 2px;
  font-weight: 600;
  margin: 2% 0% 2% 0%;
  width: 185px;
}

.likes-product {
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: center;
  background-image: linear-gradient(
    109.6deg,
    rgba(45, 115, 213, 0.542) 11.2%,
    rgba(121, 138, 212, 0.394) 91.2%
  );
  border-radius: 10px;
  width: 120px;
}

.like-text {
  font-weight: 600;
  color: rgb(255, 255, 255);
  text-shadow: 2px 2px 2px rgba(59, 59, 59, 0.25);
}

.corazon-like {
  margin-right: 4px;
}

.detailInfo {
  margin: 0% 3% 0% 3%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.combos-producto {
  margin: 3% 3% 0% 3%;
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
}

.caracteristicas-producto {
  margin: 0% 3% 0% 3%;
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
}

.title-caract {
  font-weight: 600;
  font-size: 18px;
  margin: 0% 0% 4% 0%;
}

.promos-tarjetas {
  margin: 3% 0% 0% 0%;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.box-consultar {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.button-consultar {
  text-decoration: none;
  background-color: #338f23a4;
  border-radius: 10px;
  font-weight: 600;
  font-size: 18px;
  color: white;
  text-shadow: 2px 2px 2px rgba(59, 59, 59, 0.25);
  padding: 4px;
  height: auto;
  width: auto;
  text-align: center;
  margin: 2%;
  width: 100%;
}

.remove-line {
  text-decoration: none;
}

.container-detail {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.combos-producto {
  margin: 3% 3% 0% 3%;
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
}

.order-cards-combos {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
}

.breadcrumb-nav {
  display: flex;
  align-items: center;
  margin: 20px 0;
  font-size: 14px;
}

.breadcrumb-link {
  color: #007bff;
  text-decoration: none;
  margin-right: 5px;
  transition: color 0.2s ease-in-out;
}

.breadcrumb-link:hover {
  color: #0056b3;
}

.breadcrumb-current {
  color: #6c757d;
  margin-left: 5px;
}

.breadcrumb-container {
  width: 80%;
  margin: 0% 10% 0% 10%;
}

.cart-controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f1f1f1;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 10px;
  margin-bottom: 20px;
}

.quantity-control {
  display: flex;
  align-items: center;
}

.quantity-control button {
  padding: 8px 10px;
  font-size: 14px;
  cursor: pointer;
  background-color: #1a237e;
  color: white;
  border: none;
  border-radius: 5px;
  margin: 0 5px;
  transition: background-color 0.3s ease;
}

.quantity-control button:hover {
  background-color: #0d1841;
}

.quantity-control span {
  margin: 0 8px;
  font-size: 16px;
  font-weight: bold;
  color: #333;
}

/* Media query para pantallas móviles */
@media screen and (max-width: 768px) {
  .quantity-control button {
    padding: 5px 8px;
    font-size: 12px;
    margin: 0 3px;
  }

  .quantity-control span {
    font-size: 14px;
    margin: 0 5px;
  }

  .cart-controls {
    padding: 10px;
  }
}

  
  .add-to-cart {
    padding: 10px 20px;
    background-color: #1a237e; /* Azul oscuro */
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-weight: bold;
    margin-left: 10px;
  }
  
  .add-to-cart:hover {
    background-color: #0d1841; /* Hover más oscuro */
  }
  
  .desc-producto{
    width: 100%;
  }