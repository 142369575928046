.centerBanner{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.banner-products{
    width: 100%;
    margin: 1% 0% 1% 0%;
    max-width: 800px;
}

.container-about-me{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0% 5% 5% 5%;
}

.img-sobre-mi{
    width: 50%;
    height: 50%;
    max-width: 472px;
}

.about-me{
    width: 50%;
    height: 50%;
    margin: 0% 0% 0% 5%;
    max-width: 426px;
}

@media (max-width: 900px) {
    .container-about-me {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        margin: 0% 5% 5% 5%;
    }

    .img-sobre-mi{
        width: 80%;
        height: 80%;
    }

    .about-me{
        width: 80%;
        height: 80%;
        margin: 5% 0% 0% 5%;
    }
  }