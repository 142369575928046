.banner-products{
    width: 100%;
    margin: 1% 0% 1% 0%;
}

.cardRecipes{
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.125);
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.35);
    border-radius: 10px;
    max-width: 200px;
    margin: 2% !important;
    transition: 1s;
}

.cardRecipes:hover{
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.35);
    border-radius: 10px;
    max-width: 200px;
    margin: 2% !important;
    transform: scale(1.05);
}

.img-card-recipe{
    width: 199px;
    height: 200px;
    border-radius: 10px 10px 0px 0px;
}

.title-recipe{
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    color: #212529;
    margin: 0% 0% 5% 5%;
    text-shadow: 3px 1px 4px rgba(0,0,0,0.3);
    text-align: center;
}

.acomodamos-title{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 90px;
}

.container-recipes{
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0% 10% 5% 10%;
}

.btn-prod-dec{
    text-decoration: none;
  }